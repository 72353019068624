import React from "react"
import styled, { ThemeContext } from "styled-components"
import Section from "../../../resuable/section/section"
import { dimensions, colors } from "../../../../utils/styles/styles"

import JustInTime from "../../../../images/home/clients/JustInTime.png"
import GrintaInvest from "../../../../images/home/clients/GrintaInvest.png"
import PostingChalo from "../../../../images/home/clients/PostingChalo.png"
import Flite from "../../../../images/home/clients/Flite.png"
import Row from "../../../resuable/row/row"

const StyledHomeClientsSection = styled.div`
  width: 100%;
  max-width: ${dimensions.siteWidth};
  .heading {
    margin-bottom: 30px;
    h2 {
      font-size: 20px;
      font-weight: 400;
      text-align: center;
      color: #dcdcdc;
    }
  }
  .clients {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    img {
      width: 140px;
      background: transparent;
      filter: invert(100%) contrast(0%) grayscale(100%) brightness(100%);
      transition: 0.4s all;
      &:hover {
        filter: invert(100%) contrast(120%) hue-rotate(90deg);
        transition: 0.4s all;
      }
    }
  }
  @media (max-width: 768px) {
    .heading {
      width: 80%;
    }
    .clients {
      flex-direction: row;
    }
  }
  @media (max-width: 480px) {
    padding: 40px 20px;
    .heading {
      width: 100%;
      margin-bottom: 10px;
    }
    .clients {
      flex-direction: column;
      img {
        padding: 20px 0px;
      }
    }
  }
`

const HomeClientsSection = props => {
  return (
    <Section fullwidth={true} padding="50px 10px" background="#141414">
      <StyledHomeClientsSection>
        <Row className="heading">
          <h2>
            Intelligent and Reliable insights trusted by a multitude of organizations.
          </h2>
        </Row>
        <Row className="clients">
          <img src={JustInTime} alt="Just In Time" />
          <img src={GrintaInvest} alt="Grinta Invest" />
          <img src={PostingChalo} alt="Posting Chalo" />
          <img src={Flite} alt="Flite" />
        </Row>
      </StyledHomeClientsSection>
    </Section>
  )
}

HomeClientsSection.defaultProps = {}

export default HomeClientsSection
