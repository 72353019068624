import React from 'react'
import Layout from '../components/resuable/layout/layout'
import DemoHeader from '../components/page/demo-components/demo-header/demo-header'
import { FeatureSection } from '../components/page/home-components/home-features-section'
import Joinus from '../components/resuable/joinus/joinus'
import HomeClientsSection from '../components/page/home-components/home-clients-section/home-clients-section'

const Demo = () => {
  return (
    <Layout>
      <DemoHeader />
      <HomeClientsSection/>
      <Joinus />
    </Layout>
  )
}

export default Demo
