import styled from 'styled-components'

const Input = styled.input`
  box-sizing: border-box;
  height: 48px;
  width: 100%;
  max-width: 320px;
  padding: 0px 8px;

  background: ${props => props.theme.backgroundAccent};
  color: ${props => props.theme.foreground};
  border: none;
  border-left: 4px solid #555;

  font-size: 16px;
  &::placeholder {
    color: #828282;
  }
`

export default Input;