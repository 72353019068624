/**
 * TODO Reduce code lines size. So have to make individual files for all styled components like TabControl and TabView
 */

import React, { useState } from "react"
import styled from "styled-components"
import { useTransition, animated } from "react-spring"
import Fade from "react-reveal/Fade"

import Section from "../../../../resuable/section/section"
import Row from "../../../../resuable/row/row"
import { colors, dimensions } from "../../../../../utils/styles/styles"
import { ButtonType } from "../../../../resuable/button"

import OSPGif from "../../../../../videos/gifs/newosp.gif"
import PageSeoGif from "../../../../../videos/gifs/newpageseo.gif"
import LinksenseGif from "../../../../../videos/gifs/newlinksense.gif"
import { Link } from "gatsby"

const StyledTabsHighlight = styled.div`
  width: 100%;
  max-width: ${dimensions.siteWidth};
  /* border: 2px solid red; */
  .header {
    margin: 40px 0px;
    h2 {
      color: #ededed;
      text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      font-size: 32px;
      text-align: center;
    }
  }
  .control {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .view {
    position: relative;
    width: 300%;
    height: 400px;
    height: auto;
    display: flex;
    justify-content: flex-start;
  }
  @media (max-width: 768px) {
    padding: 20px 20px;
    .header {
      h2 {
        font-size: 24px;
      }
    }
    .control {
      flex-direction: row;
      justify-content: flex-start;
    }
  }
  @media (max-width: 480px) {
    .control {
      flex-direction: column;
      justify-content: flex-start;
    }
  }
`
const TabControl = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  width: 300px;
  padding: 40px 20px;
  display: flex;
  justify-content: center;
  color: #4f4f4f;
  background: #131313;
  border-bottom: 8px solid #4f4f4f;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  font-size: 18px;
  transition: 0.4s all;
  &.active {
    color: ${props => {
      if (props.index === 0) return "#00DEAD"
      if (props.index === 1) return "#F2C94C"
      if (props.index === 2) return "#F40052"
      else return "#1258dd"
    }};
    border-bottom: 8px solid
      ${props => {
        console.log(props.index)
        if (props.index === 0) return "#00DEAD"
        if (props.index === 1) return "#F2C94C"
        if (props.index === 2) return "#F40052"
        else return "#1258dd"
      }};
  }
  &:active {
    background: lightyellow;
  }
  &:hover {
    transform: translateY(-4px);
    transition: 0.4s all;
  }
  @media (max-width: 480px) {
    width: 100%;
    padding: 10px;
    border-bottom: 4px solid transparent;
    font-size: 12px;
    &.active {
      border-bottom: 4px solid
        ${props => {
          if (props.index === 0) return "#00DEAD"
          if (props.index === 1) return "#F2C94C"
          if (props.index === 2) return "#F40052"
          else return "#1258dd"
        }};
    }
    &:hover {
      transform: translateY(0px);
      transition: 0.4s all;
    }
  }
`

const TabView = styled.div`
  /* position: absolute; */
  z-index: 1;
  height: auto;
  width: 100%;
  padding: 60px 0px 20px;
  display: flex;
  justify-content: flex-start;
  /* border: 2px solid green; */
  div.container__text {
    padding-right: 20px;
    flex: 1 1 40%;
    h3 {
      font-size: 24px;
    }
    h3 {
      color: ${props => {
        if (props.index === 0) return "#00DEAD"
        if (props.index === 1) return "#F2C94C"
        if (props.index === 2) return "#F40052"
        else return "#1258dd"
      }};
    }
    button {
      color: ${props => {
        if (props.index === 0) return "#00DEAD"
        if (props.index === 1) return "#F2C94C"
        if (props.index === 2) return "#F40052"
        else return "#1258dd"
      }};
      border-color: ${props => {
        if (props.index === 0) return "#00DEAD"
        if (props.index === 1) return "#F2C94C"
        if (props.index === 2) return "#F40052"
        else return "#1258dd"
      }};
    }
  }
  div.container__image {
    height: 100%;
    padding-left: 20px;
    flex: 1 1 60%;
    img {
      width: 100%;
      height: 100%;
      margin: 0px;
      object-fit: cover;
    }
  }
  opacity: 0;
  transition: 1.6s all;
  &.active {
    z-index: 2;
    opacity: 1;
    transition: 1.6s all;
  }
  @media (max-width: 768px) {
    padding: 30px 0px 20px;
    flex-direction: column;
    div.container__text,
    div.container__image {
      flex: 1 1 100%;
      padding: 0px;
    }
    div.container__image {
      margin-top: 20px;
      image {
        height: auto;
      }
    }
  }
`

const TabsHighlight = props => {
  const [index, setIndex] = useState(0)

  const handleClick = e => {
    console.log(parseInt(e.target.id))
    let currentIndex = parseInt(e.target.id)
    setIndex(currentIndex)
  }
  return (
    <Section fullwidth={true} background={colors.blackGradient}>
      <StyledTabsHighlight index={index}>
        <Row className="header">
          <h2>A three fold approach to grow your website and business.</h2>
        </Row>
        {/* Control */}
        <Row className="control">
          <TabControl
            index={index}
            id="0"
            onClick={handleClick}
            className={index === 0 && "active"}
          >
            Competitor Perspective
          </TabControl>
          <TabControl
            index={index}
            id="1"
            onClick={handleClick}
            className={index === 1 && "active"}
          >
            Page Optimization
          </TabControl>
          <TabControl
            index={index}
            id="2"
            onClick={handleClick}
            className={index === 2 && "active"}
          >
            Link Building
          </TabControl>
        </Row>

        {/* View */}
        <Row className="view">
          {index === 0 && (
            <Fade>
              <TabView index={index} className={index === 0 && "active"}>
                <div className="container__text">
                  <h3>
                    Stop assuming your visibility and watch reality in action.
                  </h3>
                  <p>
                    Hub for understanding the direction of you and your
                    competitors’ marketing activities. OSP gets into action
                    every day to help you answer the following:
                  </p>
                  <Link to="features/organic-sense?ref=homepage">
                    <ButtonType.Ghost id="features-learnmore_OSP">
                      Learn More
                    </ButtonType.Ghost>
                  </Link>
                </div>
                <div className="container__image">
                  <img src={OSPGif} alt="some feature of LM" />
                </div>
              </TabView>
            </Fade>
          )}
          {index === 1 && (
            <Fade>
              <TabView index={index} className={index === 1 && "active"}>
                <div className="container__text">
                  <h3>
                    Structure your SEO meta by inspecting multiple page elements
                  </h3>
                  <p>
                    A mirror of your structural SEO. In-Page allows you to
                    reverse engineer the entire SEO strategy of any given
                    webpage by providing answers for following data on a daily
                    basis.
                  </p>
                  <Link to="features/in-page?ref=homepage">
                    <ButtonType.Ghost id="features-learnmore_PSEO">
                      Learn More
                    </ButtonType.Ghost>
                  </Link>
                </div>
                <div className="container__image">
                  <img src={PageSeoGif} alt="some feature of LM" />
                </div>
              </TabView>
            </Fade>
          )}
          {index === 2 && (
            <Fade>
              <TabView index={index} className={index === 2 && "active"}>
                <div className="container__text">
                  <h3>Stronger the links, better the authority.</h3>
                  <p>
                    LinkSense is built as the most efficient link building
                    mechanism. It is astonishingly simple as you enter a URL and
                    within minutes, the entire link building strategy of the
                    page along with the following metrics will be available for
                    analysis.
                  </p>
                  <Link to="features/link-sense?ref=homepage">
                    <ButtonType.Ghost id="features-learnmore_LS">
                      Learn More
                    </ButtonType.Ghost>
                  </Link>
                </div>
                <div className="container__image">
                  <img src={LinksenseGif} alt="some feature of LM" />
                </div>
              </TabView>
            </Fade>
          )}
        </Row>
      </StyledTabsHighlight>
    </Section>
  )
}

TabsHighlight.defaultProps = {}

export default TabsHighlight
