import React, { useState } from "react"
import styled from "styled-components"
import Hero from "../../../resuable/hero/hero"
import { colors, dimensions } from "../../../../utils/styles/styles"
import Section from "../../../resuable/section/section"
import Input from "../../../resuable/form/input/input"
import { ButtonType } from "../../../resuable/button"

const StyledDemoHeader = styled.div`
  position: relative;
  width: 100%;
  height: 65vh;
  display: flex;
  justify-content: center;
  background: #141414;
  div.container__background {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: ${colors.priGradient};
    clip-path: polygon(0 0, 100% 0%, 100% 45%, 0% 100%);
  }
  div.container__header {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: ${dimensions.siteWidth};
    padding-top: 80px;
    padding-bottom: 60px;
    display: flex;
    justify-content: center;
    div.text,
    div.form {
      border: none;
    }
    div.text {
      padding-right: 40px;
      max-width: 400px;
      h1 {
        font-size: 42px;
        text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
      }
      h2 {
        font-size: 20px;
        font-weight: 400;
        color: #edeedd;
      }
    }
    div.form {
      padding-left: 40px;
      form {
        border-radius: 10px;
        box-sizing: border-box;
        width: 380px;
        padding: 40px 30px;
        box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.25);
        background: #1f1f1f;
        input {
          background: #333;
          &:not(:last-child) {
            margin-bottom: 30px;
          }
        }
        button {
          width: 100%;
          display: flex;
          justify-content: center;
        }
        span.status {
          width: 100%;
          margin-top: 10px;
          padding: 4px 14px;
          display: inline-block;
          color: #fff;
          background: ${colors.pri};
          font-size: 8px;
          text-align: center;
          border-radius: 10px;
        }
      }
    }
  }
  @media (max-width: 768px) {
    height: 90vh;
    div.container__header {
      padding: 0 20px;
      flex-direction: column;
      justify-content: flex-start;
      div.text,
      div.form {
        box-sizing: border-box;
        padding: 0px;
      }
      div.text {
        width: 100%;
        max-width: none;
        margin: 90px 0 0px;
        h1 {
          font-size: 30px;
        }
        h2 {
          font-size: 20px;
        }
      }
      div.form {
        margin: 0px 0 180px;
        form {
          width: 100%;
          input {
            max-width: none;
          }
        }
      }
    }
  }
`

const DemoHeader = props => {
  const [input, setInput] = useState({
    name: "",
    organization: "",
    contact_num: "",
    email_id: "",
    message: "DEMO REQUEST",
  })

  const [responseStatus, setResponseStatus] = useState("presubmit")

  const handleChange = e => {
    let newInput = input
    newInput[e.target.name] = e.target.value
    setInput({
      ...newInput,
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setResponseStatus("submitting")
    // API Config
    const config = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    }
    // API Call
    try {
      const response = await fetch(
        "https://api.leadmirror.com/api/v1/homepage/contact-us/",
        config
      )
      console.log(response.status)
      setResponseStatus("submitted")
    } catch (error) {
      setResponseStatus("error")
    }
  }
  return (
    <Section fullwidth={true} padding="0px">
      <StyledDemoHeader>
        <div className="container__background"></div>
        <div className="container__header">
          <div className="text">
            <h1>Schedule a Free Live demo</h1>
            <h2>
              Tell us a few things about yourself and we'll show you a lot more
              about us.
            </h2>
          </div>
          <div className="form">
            <form onSubmit={handleSubmit}>
              <Input
                type="text"
                name="name"
                placeholder="Your Full Name"
                value={input.name}
                onChange={handleChange}
                isrequired
              />
              <Input
                type="text"
                name="email_id"
                placeholder="Your Business Email"
                value={input.email_id}
                onChange={handleChange}
                isrequired
              />
              <Input
                type="text"
                name="contact_num"
                placeholder="Your Phone Number"
                value={input.contact_num}
                onChange={handleChange}
                isrequired
              />
              <ButtonType.Base variant="secondary">
                Schedule a demo
              </ButtonType.Base>
                {responseStatus === "presubmit" && null}
                {responseStatus === "submitting" && <span className="status">Submitting your request...</span>}
                {responseStatus === "submitted" &&  <span className="status">Submitted!</span>}
            </form>
          </div>
        </div>
      </StyledDemoHeader>
    </Section>
  )
}

DemoHeader.defaultProps = {}

export default DemoHeader
